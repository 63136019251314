import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Flex, Box } from "../components/FlexBox";
import ImgTextSection from "../components/sections/ImgTextSection";
import { Paragraph } from "../components/parts/typography";

const Leistungen = ({ data }) => {
    const Img1 = data.homeImage.childImageSharp.fluid;
    const Img2 = data.homeImage2.childImageSharp.fluid;
    const Img3 = data.homeImage3.childImageSharp.fluid;
    return (
        <Layout>
            <Flex
                alignItems="center"
                flexDirection="column"
                width="calc(100% + 40px)"
            >
                <Flex width="100%" justifyContent="center">
                    <Box
                        maxWidth="980px"
                        mb={[0, "36px"]}
                        mx="-20px"
                        width="100%"
                    >
                        {/* first image */}
                        <ImgTextSection
                            order="1"
                            fluid={Img1}
                            headline="Leistungen"
                            textAlign={["left", "right"]}
                            flexTextAlign={["flex-start", "flex-end"]}
                        >
                            <Paragraph mb="20px">
                                Die IGK BERLIN MBH übernimmt die Planung und
                                Realisierung von Neubauten, sowie die
                                Modernisierung und den Umbau von
                                Bestandsgebäuden. Von der konzeptionellen
                                Entwurfsplanung bis zur baulichen Umsetzung
                                werden alle Leistungsphasen angeboten.
                            </Paragraph>
                            <Paragraph>
                                Das Spektrum realisierter Projekte des
                                Architekturbüros reicht von Büro- und
                                Wohngebäude, über Geschäftsobjekte bis hin zum
                                privaten Wohnbau. Ferner sind wir als
                                zertifizierter Sachverständiger für
                                barrierefreie Gebäudeplanung bei der
                                Architektenkammer Berlin gelistet.
                            </Paragraph>
                        </ImgTextSection>
                    </Box>
                </Flex>
                <Flex
                    width="100%"
                    mt={["33px", "42px"]}
                    mb={[0, "36px"]}
                    flexDirection="column"
                    alignItems="center"
                >
                    <Box maxWidth="980px" mx="-20px" px="2px" width="100%">
                        {/* second image */}
                        <ImgTextSection
                            order={["1", "2", "2"]}
                            fluid={Img2}
                            headline="Leistungsspektrum"
                            imgMaxWidth={["100%", "455px"]}
                        >
                            <Paragraph>
                                Das Leistungsspektrum der Projektbearbeitung
                                reicht von
                                <br />
                                Büro - Deutsche Rentenversicherung Bund
                                <br />
                                Freizeit - MeridianSpa ViktoriaPark, Sauna /
                                Wellnesskomplex am Scharmützelsee
                                <br />
                                Hotelbau - Universitätsklinikum Mannheim, NH
                                Hotel Potsdamer Platz
                                <br />
                                Einkaufzentren - Kaufland Berlin
                                <br />
                                Wohnbauten - Wohnquartier Eiswerder
                                <br />
                                bis zu Bauen im Bestand, auch mit
                                denkmalpflegerischem Profil - barocke
                                Stadterweiterung Potsdam, Rosenthaler und
                                Spandauer Vorstadt in Berlin.
                            </Paragraph>
                        </ImgTextSection>
                    </Box>
                    <Box mt={["32px", "79px"]} mx="-20px" px="2px" width="100%">
                        {/* third image */}
                        <ImgTextSection
                            order="1"
                            fluid={Img3}
                            textAlign={["left", "right"]}
                            flexTextAlign={["flex-start", "flex-end"]}
                            headline="Schwerpunkt"
                        >
                            <Paragraph>
                                Als Schwerpunkt unserer beruflichen Tätigkeit
                                hat sich zunehmend die Leistungsphase der
                                Ausführungsplanung herauskristallisiert. Nach
                                unserer Philosophie sind Ausführungszeichnungen
                                im Rahmen der Ausführungsplanung die
                                vollständige zeichnerische Darstellung der
                                geplanten baulichen Anlage. Sie berücksichtigen
                                die städtebaulichen, funktionalen, technischen
                                und bauphysikalischen Anforderungen unter
                                Verwendung der Beiträge anderer an der Planung
                                fachlich Beteiligter. Ausführungszeichnungen
                                bilden in Form von Werk- und Detailzeichnungen
                                die Grundlage für die Leistungsbeschreibung und
                                die Ausführung baulicher Anlagen. Sie enthalten
                                alle für die Ausführung erforderlichen
                                Einzelangaben und werden zeichnerisch und
                                textlich bestimmt.
                            </Paragraph>
                        </ImgTextSection>
                    </Box>
                </Flex>
            </Flex>
        </Layout>
    );
};

export default Leistungen;

export const ImageQuery = graphql`
    query {
        homeImage: file(
            relativePath: { eq: "leistungen/1_leistungen_baustelle.jpg" }
        ) {
            childImageSharp {
                fluid(webpQuality: 70) {
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        homeImage2: file(
            relativePath: { eq: "leistungen/2_leistungen_hakeburg.jpg" }
        ) {
            childImageSharp {
                fluid(webpQuality: 70) {
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        homeImage3: file(
            relativePath: { eq: "leistungen/3_schwerpunkt_leistungen.png" }
        ) {
            childImageSharp {
                fluid(webpQuality: 70) {
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`;
