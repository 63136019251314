import React from "react";
import { Link as _Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { typography, space } from "styled-system";
import { Flex, Box } from "../FlexBox";
import { ParagraphDiv, Headline } from "../parts/typography";
import { colors } from "../../utilities/colors";
import Arrow from "../../css/arrow.svg";

const Link = styled(_Link)`
    font-style: italic;
    text-decoration: none;
    display: inline-block;
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    align-items: center;
    &:hover {
        color: ${colors.red};
        > svg {
            color: ${colors.red};
        }
    }
    ${typography};
    ${space};
`;

function ImgTextSection({ children, ...props }) {
    return (
        <Flex height={props.height} flexWrap="wrap">
            <Box
                flex="1 0 50%"
                flexBasis={["100%", "50%"]}
                order={props.order}
                textAlign={props.textAlign}
                p="18px"
                pt={props.order === "2" ? ["0", "18px"] : "18px"}
            >
                <Flex flexDirection="column" alignItems={props.flexTextAlign}>
                    <Box mt={props.order === "2" ? ["0", "30px"] : [0, "30px"]}>
                        <Headline
                            fontSize={props.fontSize}
                            headlineFontWeight={props.headlineFontWeight}
                        >
                            {props.headline}
                        </Headline>
                    </Box>
                    <Box maxWidth={["100%", "418px"]}>
                        <ParagraphDiv>{children}</ParagraphDiv>
                    </Box>
                </Flex>
                {props.link && (
                    <Flex
                        display={["none", "flex"]}
                        alignItems="center"
                        mt="36px"
                    >
                        <Link to={props.linkUrl} fontSize="22px">
                            {props.link}
                            <img
                                alt="arrow"
                                src={Arrow}
                                style={{
                                    width: "13px",
                                    height: "19px",
                                    marginTop: "1px",
                                    marginLeft: "12px",
                                }}
                            />
                        </Link>
                    </Flex>
                )}
                {props.link && (
                    <Flex
                        display={["flex", "none"]}
                        alignItems="center"
                        justifyContent="flex-end"
                        mb="14px"
                        mt="26px"
                    >
                        {/* mobile */}
                        <Link
                            to={props.linkUrl}
                            fontSize="17px"
                            fontWeight="600"
                        >
                            {props.link}
                            <img
                                alt="arrow"
                                src={Arrow}
                                style={{
                                    width: "10px",
                                    height: "15px",
                                    fontSize: "16px",
                                    marginTop: "1px",
                                    marginLeft: "9px",
                                }}
                            />
                        </Link>
                    </Flex>
                )}
            </Box>
            <Flex
                flex={props.fbasis ? "0 0 50%" : "1 0 50%"}
                flexBasis={[props.fbasis ? "50%" : "100%", "50%"]}
                justifyContent={props.order === "1" ? "flex-start" : "flex-end"}
                alignItems="center"
                width="100%"
                pt={[0, "18px"]}
                pr="18px"
                pb="18px"
                pl="18px"
                order="1"
            >
                <Box width="100%" maxWidth={props.imgMaxWidth}>
                    <Img fluid={props.fluid} style={{ width: "100%" }} />
                </Box>
            </Flex>
        </Flex>
    );
}

export default ImgTextSection;
